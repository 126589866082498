<template>
    <div >
        <div class="search-nav">
            <div class="search-form">
                <el-form :inline="true" ref="listQuery" :model="listQuery">
                    <div class="form">
                        <div class="search-conditions">   
                            <el-form-item label="关键字" prop="keyword">
                                <el-input class="input-w290-h32" v-model="listQuery.keyword" placeholder="请输入主叫/被叫号码" @keyup.enter.native="getList('search')"></el-input>
                            </el-form-item>
                            <!-- <el-form-item label="呼叫模式" prop="alarmTypes" >
                              <el-select clearable class="date-w210-h32 select-customize-input" popper-class="select-customize" v-model="listQuery.alarmTypes" placeholder="请选择">
                                <el-option
                                  v-for="item in options"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                                </el-option>
                              </el-select>
                            </el-form-item>  -->
                            <el-form-item label="时间范围" prop="startTime"  class="clear-margin-right">
                                <el-date-picker class="date-w210-h32" align="right" size="small" v-model="listQuery.startTime" type="datetime" placeholder="请选择开始时间"
                                    value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerStartDateAlart"></el-date-picker>
                                <span class="time-interval">至</span>
                            </el-form-item>
                            <el-form-item label="" prop="endTime">
                                <el-date-picker class="date-w210-h32" align="right" size="small" v-model="listQuery.endTime" type="datetime" :default-time="new Date()+''"
                                    placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerEndDateAlart"></el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="search-btns">
                            <el-form-item style="display:none">
                            </el-form-item>
                            <tem-btn  type="primary" value="查询" @click="getList('search')"></tem-btn>
                            <tem-btn  type="info" value="清空" @click="resetForm('listQuery')"></tem-btn>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="table-list">
            <Table :table="table" :total="listQuery.total" :page.sync="listQuery.pageIndex" :size.sync="listQuery.pageSize"
            :orderBy.sync="listQuery.orderField" :sortIndex.sync="listQuery.sort" @pagination="getList"></Table>
        </div>
        <div class="copyright">Copyright©2018-{{(new Date()).getFullYear()}} 重庆几米信息技术有限公司 版权所有</div>
  </div>
</template>
<script>
    import Table from '@/components/table/table';
    import {listPageCallLog,exportCallLog} from "@/api/callStatistics";
    export default {
        components: {
            Table
        },
        data() {
            return {
                pickerStartDateAlart: {
                    disabledDate: (time) => { // 限制开始时间小于结束时间
                        if (this.listQuery.endTime) {
                            if(this.listQuery.endTime.split(' ')[1] ==  '00:00:00') {
                                return time.getTime() > new Date(this.listQuery.endTime.split(' ')[0]).getTime() || time.getTime() > Date.now();
                            } else {
                                return time.getTime() > new Date(this.listQuery.endTime.split(' ')[0]).getTime() || time.getTime() > Date.now();
                            }
                        } else {
                            return time.getTime() > Date.now();
                        }
                    }
                },
                // 结束时间
                pickerEndDateAlart: {
                    disabledDate: (time) => { // 限制开始时间小于结束时间
                    
                        if (this.listQuery.startTime) {
                            if( this.listQuery.startTime.split(' ')[1] ==  '00:00:00' || this.listQuery.startTime.split(' ')[0] == this.format(new Date(),'-').split(' ')[0]) {
                                return  time.getTime() < new Date(this.listQuery.startTime.split(' ')[0]).getTime() - 8.64e7 || time.getTime() > Date.now();
                            }  else {
                                return time.getTime() < new Date(this.listQuery.startTime.split(' ')[0]).getTime() - 8.64e7 || time.getTime() > Date.now();
                            }
                            
                        } else {
                            return time.getTime() > Date.now();
                        }
                    }
                },
                //搜索栏数据
                listQuery:{
                    keyword: '',
                    beginTime: '',//开始时间 
                    beginTime: '',//结束时间 
                    total:10000,//数据条数
                    pageIndex: 1,//当前查询页面
                    pageSize: 10,//每页数据数量
                    orderField:null,
                    sort:''
                },
                //表格数据
                table: {
                    configItem: true, //是否开启表格项配置
                    data: [],
                    //表头按钮
                    operateBtns:[
                        {
                            value:'导出EXCEL',
                            type:'primary',
                            event:() => {
                                this.exportCallLog()
                            }
                        },
                    ],
                    header: [
                        {
                            type: 'index',
                            label: '序号',
                            width: '80',
                            ifShow: true,
                        },
                        // {
                        //     prop: 'callId',
                        //     label: 'id',
                        //     ifShow: false,
                        // },
                        {
                            prop: 'callPhone',
                            label: '主叫号码',
                            minWidth: '120',
                            ifShow: true
                        },
                        {
                            prop: 'linkPhone',
                            label: '被叫号码',
                            minWidth: '120',
                            ifShow: true
                        },
                        // {
                        //     prop: 'state',
                        //     label: '呼叫模式',
                        //     tooltip: true,
                        //     ifShow: true,
                        // },
                        {
                            prop: 'driverName',
                            label: '车主',
                            ifShow: true
                        },
                        {
                            prop: 'carBrand',
                            label: '车牌号',
                            minWidth: '180',
                            ifShow: true
                        },{
                            prop: 'minute',
                            label: '通话时长/分',
                            minWidth: '180',
                            ifShow: true
                        },{
                            prop: 'startTime',
                            label: '呼叫时间',
                            minWidth: '180',
                            sortable: 'column',
                            ifShow: true
                        }
                    ],
                },
            }
        },
        created(){
            this.getList();
        },
        methods: {
             // 时间补0
            supplement(nn) {
                return (nn = nn < 10 ? "0" + nn : nn);
            },
            // 转换时间格式
            format(Date, str) {
                // debugger
                var obj = {
                    Y: Date.getFullYear(),
                    M: Date.getMonth() + 1,
                    D: Date.getDate(),
                    H: Date.getHours(),
                    Mi: Date.getMinutes(),
                    S: Date.getSeconds(),
                };
                // 拼接时间 hh:mm:ss
                var time =
                    " " +
                    this.supplement(obj.H) +
                    ":" +
                    this.supplement(obj.Mi) +
                    ":" +
                    this.supplement(obj.S);
                // yyyy-mm-dd
                if (str.indexOf("-") > -1) {
                    return (
                    obj.Y +
                    "-" +
                    this.supplement(obj.M) +
                    "-" +
                    this.supplement(obj.D) +
                    time
                    );
                }
                // yyyy/mm/dd
                if (str.indexOf("/") > -1) {
                    return (
                    obj.Y +
                    "/" +
                    this.supplement(obj.M) +
                    "/" +
                    this.supplement(obj.D) +
                    time
                    );
                }
            },
            getList(val){
                if(val == "search"){
                    this.listQuery.pageIndex =1 ;
                }
                if ((!this.listQuery.startTime && this.listQuery.endTime) || (this.listQuery.startTime && !this.listQuery.endTime)) {
                    this.$message({type:'warning',message:'请重新选择完整的时间！'})
                    return;
                }
                if (this.listQuery.startTime > this.listQuery.endTime) {
                    this.$message({type:'warning',message:'开始时间大于结束时间，请重新选择！'})
                    return;
                }
                listPageCallLog(this.listQuery).then(res => {
                    // console.log(res)
                    this.table.data = res.data;
                    this.listQuery.total = res.count; 
                })
            },
            resetForm(formName) { 
                this.$refs[formName].resetFields();
                if(formName == 'form1'){
                this.dialogForm1Visible = false
                }else{
                this.dialogForm2Visible = false;
                }
            },
            /** 导出文件 */
            exportCallLog() {
                exportCallLog(this.listQuery).then( res => {
                    // 使用blob下载文件
                    const blob = new Blob([res]);
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    // 转换文件文字为中文
                    downloadElement.download = '呼叫统计记录.xlsx';
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement); // 下载完成移除元素
                    window.URL.revokeObjectURL(href); // 释放掉blob对象
                })
            },
        }
    }
</script>
<style lang="scss" scoped>
.box{
  overflow: auto;
  max-height: 100px;
  margin-top: 20px;
}
.box div{
  color: #fff;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 2px 4px;
  font-size: 12px;
  border-radius: 2px;
  background-color: #409EFF;
}

</style>
