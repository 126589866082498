import request from '@/utils/request';

/** 分页查询 */
export function listPageCallLog(data) {
    return request({
        url: '/movecar/callLog/listPageCallLog',//请求路径
        method: 'get',
        params:data
    });
}
/** 分页查询 */
export function exportCallLog(data) {
    return request({
        url: '/movecar/callLog/exportCallLog',//请求路径
        method: 'get',
        params:data,
        responseType: 'blob'    //重点重点，，位置要写对
    });
}